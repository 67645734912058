import * as React from "react"
import Layout from "../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconLink from "../components/icon-link";
import {graphql} from "gatsby";
import {RichText} from "prismic-reactjs";
import GatsbyLink from "../components/gatsby-link";

// markup
const NotFoundPage = ({ data }) => {
  const document = data.prismicNotFound.data;
  return (
    <Layout title={RichText.asText(document.titel.raw)}>
      <section>
        <Row>
          <Col>
            <h1>{RichText.asText(document.titel.raw)}</h1>
            <RichText render={document.content.raw} serializeHyperlink={GatsbyLink}/>
            <IconLink left icon="FaArrowLeft">{document.homepage_link}</IconLink>
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    prismicNotFound {
      data {
        titel {
          raw
        }
        content {
          raw
        }
        homepage_link
      }
    }
  }
`


export default NotFoundPage
